<template>

    <b-sidebar id="concept-store-pm-shortcut" :visible="isConceptShortcutPmActive" bg-variant="white"
               sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetDetail"
               @change="(val) => $emit('update:is-concept-shortcut-pm-active', val)">
        <template #default="{ hide }">

            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    Edit Concept PM
                </h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
            </div>
            <b-card-text v-if="!isLoading" class="px-2 py-1">
                <div class="row" v-if="conceptPms">
                    <div class="col-12 card-app-design">

                        <div class="mt-1 text-left">
                            <hr/>
                            <b-form-group label="PM" label-for="statusSelect">
                                <v-select id="statusselect"
                                          :options="conceptPms" :reduce="(option) => option.id"
                                          label="name"
                                          v-model="conceptEnumValue"
                                          placeholder="Select PM" class="w-100"/>
                            </b-form-group>
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                                          @click="updateConceptPm()">
                                    Update
                                </b-button>

                            </div>
                        </div>
                    </div>
                </div>
            </b-card-text>
            <div v-else-if="isLoading" class="text-center my-2 px-2 py-1">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BCardText,
    BBadge,
    BAvatar,
    VBTooltip,
    BMediaAside,
    BMedia,
    BMediaBody,
    BSpinner,
    BFormGroup,
    BSidebar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {avatarText} from '@core/utils/filter'
import vSelect from 'vue-select'
import {mapState} from 'vuex'
import moment from 'moment'
import useConceptApi from '@/composables/useConceptApi'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import conceptList from "@/views/concept/ConceptList";

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        BCardText,
        BBadge,
        BAvatar,
        BMediaAside,
        BMedia,
        BMediaBody,
        BSpinner,
        BFormGroup,
        vSelect,
        BSidebar
    },
    model: {
        prop: 'isConceptShortcutPmActive',
        event: 'update:is-concept-shortcut-pm-active',
    },
    props: {
        modalDate: {
            type: String,
            required: false,
        },
        conceptPm: {
            required: false
        },
        isConceptShortcutPmActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            conceptEnumValue: this.conceptPm
        }
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    setup() {
        const {
            conceptPmList,
            fetchPms,
        } = useConceptApi()
        return {
            conceptPmList,
            fetchPms,
            avatarText,
        }
    },
    computed: {
        ...mapState('conceptStore', ['conceptShortCut', 'isLoading', 'conceptPms']),
    },
    methods: {
        resetDetail() {
            this.$store.dispatch('conceptStore/resetDetailView');
        },
        updateConceptPm() {
            if (typeof this.conceptEnumValue === 'number') {
                this.conceptShortCut.pm = this.conceptEnumValue
                this.conceptShortCut.concept_genre_id = this.conceptShortCut.genre.id
                this.conceptShortCut.concept_camera_id = this.conceptShortCut.camera.id
                this.$store.dispatch('conceptStore/updateConcept', this.conceptShortCut)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully updated!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        this.$emit('refetch-data')
                        this.$emit('update:is-concept-shortcut-pm-active', false)
                        this.conceptEnumValue = null
                    }).catch((errors) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please check form',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: errors.response ? errors.response.data.message : ''
                        },
                    })
                })
            }
        }
    },
    watch: {
        conceptPm(newValue) {
            this.conceptEnumValue = newValue
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
