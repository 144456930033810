<template>
  <b-sidebar id="concept-store-shortcut" :visible="isConceptShortcutActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetDetail"
    @change="(val) => $emit('update:is-concept-shortcut-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Concept Status
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>
        <b-card-text v-if="!isLoading" class="px-2 py-1">
            <div class="row" v-if="conceptShortCut">
                <div class="col-12 card-app-design">
                    <div class="d-flex align-items-center">
                        <div class="icon mr-1" v-if="conceptShortCut.camera" v-html="conceptShortCut.camera.icon">
                        </div>
                        <div class="info">
                            <h3 class="mb-0">{{conceptShortCut.name}}</h3>
                            <span class="text-gray font-12">{{conceptShortCut.status}}</span>
                        </div>
                    </div>
                    <div class="mt-1 text-left">
                        <hr/>
                        <b-form-group label="Status" label-for="statusSelect">
                            <v-select id="statusselect"
                            :options="statusOptions" :reduce="(option) => option.value" v-model="conseptEnumValue"
                            placeholder="Select Status" class="w-100" />
                        </b-form-group>
                        <div class="d-flex mt-2">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="updateConceptStatus()">
                                Update
                            </b-button>

                        </div>
                    </div>
                </div>
            </div>
        </b-card-text>
        <div v-else-if="isLoading" class="text-center my-2 px-2 py-1">
            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
            <strong>Loading...</strong>
        </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,BButton,BCardText,BBadge, BAvatar, VBTooltip,BMediaAside,BMedia,BMediaBody,BSpinner, BFormGroup,BSidebar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import moment from 'moment'
import useConceptApi from '@/composables/useConceptApi'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        BCardText,
        BBadge,
        BAvatar,
        BMediaAside,
        BMedia,
        BMediaBody,
        BSpinner,
        BFormGroup,
        vSelect,
        BSidebar
    },
    model: {
        prop:'isConceptShortcutActive',
        event: 'update:is-concept-shortcut-active',
    },
    props: {
        modalDate: {
            type: String,
            required: false,
        },
        conceptStatus:{
            type:String,
            required:false,
        },
        isConceptShortcutActive: {
            type: Boolean,
            required: true,
        },
    },
    data(){
        return {
            conseptEnumValue:this.conceptStatus,
        }
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    setup() {
        const {
            statusOptions,
            refetchData,
        } = useConceptApi()
        return {
            statusOptions,
            refetchData,
            avatarText,
        }
    },
    computed: {
        ...mapState('conceptStore', ['conceptShortCut', 'isLoading']),
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('HH:mm');
        },
        resetDetail() {
            this.$store.dispatch('conceptStore/resetDetailView');
        },
        updateConceptStatus(){
            this.conceptShortCut.status = this.conseptEnumValue
            this.conceptShortCut.pm = this.conceptShortCut.pm?.id
            this.conceptShortCut.concept_genre_id = this.conceptShortCut.genre.id
            this.conceptShortCut.concept_camera_id = this.conceptShortCut.camera.id
            this.$store.dispatch('conceptStore/updateConcept', this.conceptShortCut)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Successfully updated!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        },
                    })
                    this.$emit('refetch-data')
                    this.$emit('update:is-concept-shortcut-active', false)
                }).catch((errors) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please check form',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: errors.response ? errors.response.data.message : ''
                        },
                    })
                })
        }
    },
    watch:{
        conceptStatus(newValue){
            this.conseptEnumValue = newValue
        },
    }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
