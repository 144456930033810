<template>

    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 ml-1" />
                    </b-col>
                    <b-col cols="12" md="10">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col cols="12" md="2" class="mb-md-0 mb-2" v-if="$can('read', 'conceptTeamAccess')">
                                <v-select v-if="teamList" @input="pmFilter = null" :options="teamList"
                                    :reduce="(option) => option.value" v-model="teamFilter" placeholder="Select Team"
                                    class="w-100" />
                            </b-col>

                            <b-col cols="12" md="2" class="mb-md-0 mb-2" v-if="conceptPmList">
                                <v-select v-model="pmFilter" :reduce="(option) => option.id" label="name"
                                    :options="conceptPmList" :clearable="true" placeholder="Select PM">
                                    <template #option="{ image, name }">
                                        <b-avatar size="18" :src="image" :text="avatarText(name)" />
                                        <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                                    </template>
                                    <template #selected-option="{ image, name }">
                                        <b-avatar size="18" :src="image" :text="avatarText(name)" />
                                        <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                                    </template>
                                </v-select>
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <v-select :options="statusOptions" :reduce="(option) => option.value"
                                    v-model="statusFilter" placeholder="Select Status" class="w-100" />
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2" v-if="genreFetchOptions">
                                <v-select :options="genreFetchOptions" :reduce="(option) => option.id" label="label"
                                    v-model="genreFilter" placeholder="Select Genre" class="w-100" />
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                    placeholder="Search..." />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refBuildListTable" class="position-relative" :items="fetchConcepts" responsive
                :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                :fields="$can('read', 'conceptTeamAccess')?tableTeamColumns:tableColumns" primary-key="id"
                :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- Column: id -->
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                    {{ data.item.name }}
                </template>

                <!-- Column: Theme -->
                <template #cell(theme)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.theme }}</span>
                </template>

                <!-- Column: Genre -->
                <template #cell(concept_genre_id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.genre.name }}</span>
                </template>

                <!-- Column: Creator -->
                <template #cell(user_id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.user ? data.item.user.name : '-' }}</span>
                </template>


                <template #cell(status)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.status }} </span>
                    <b-link v-if="$can('read', 'conceptApprove')" class="mr-1"
                        @click="shortcutMenu(data.item.id,data.item.created_at,data.item.enum_status)"
                        v-b-tooltip.hover.top="'Shortcut Status Edit'">
                        <feather-icon icon="EditIcon" />
                    </b-link>
                </template>

                <template #cell(team_id)="data" v-if="$can('read', 'conceptTeamAccess')">
                    <span class="align-text-top text-capitalize">{{ data.item.team?data.item.team.name:'-' }} </span>
                    <b-link v-if="$can('read', 'conceptApprove')" class="mr-1"
                        @click="shortcutTeamMenu(data.item.id,data.item.created_at,data.item.team_id)"
                        v-b-tooltip.hover.top="'Shortcut Team Edit'">
                        <feather-icon icon="EditIcon" />
                    </b-link>
                </template>

                <template #cell(pm_id)="data">
                    <span class="align-text-top text-capitalize">{{
                    data.item.pm ? data.item.pm.name : '-'
                    }} </span>
                    <b-link v-if="$can('read', 'conceptApprove')" class="mr-1"
                        @click="shortcutPmMenu(data.item.id,data.item.created_at,data.item.pm,data.item.team_id)"
                        v-b-tooltip.hover.top="'Pm Edit'">
                        <feather-icon icon="EditIcon" />
                    </b-link>
                </template>

                <!-- Column: Created at -->
                <template #cell(created_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
                </template>

                <!-- Column: Created at -->
                <template #cell(updated_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.updated_at) }}</span>
                </template>

                <!--Column: Actions -->
                <template #cell(actions)="data">
                    <b-link class="mr-1" v-if="$can('read', 'conceptList')"
                        :to="{ name: 'concept-detail', params: { concept: data.item.id } }"
                        v-b-tooltip.hover.top="'Detail Concept'">
                        <feather-icon icon="EyeIcon" size="18" />
                    </b-link>

                    <b-link class="mr-1"
                        v-if="($can('read', 'conceptEdit') && (userData.id == (data.item.user?data.item.user.id:null) || userData.id == data.item.pm_id)) || $can('read', 'conceptTown')"
                        :to="{ name: 'concept-edit', params: { concept: data.item.id } }"
                        v-b-tooltip.hover.top="'Edit Concept'">
                        <feather-icon icon="EditIcon" size="18" />
                    </b-link>

                    <b-link class="mr-1"
                        v-if="($can('read', 'conceptDelete') && userData.id == (data.item.user?data.item.user.id:null)) || $can('read', 'conceptTown')"
                        v-b-tooltip.hover.top="'Delete Concept'" v-on:click="confirmDelete(data.item.id)">

                        <feather-icon icon="TrashIcon" size="18" />
                    </b-link>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                            entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalBuilds" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <concept-shortcut-edit :is-concept-shortcut-active.sync="isConceptShortcutActive" :modalDate="modalDate"
            :conceptStatus="conceptStatus" @refetch-data="refetchData" />

        <concept-shortcut-pm-edit :is-concept-shortcut-pm-active.sync="isConceptShortcutPmActive" :modalDate="modalDate"
            :conceptPm="conceptPm" @refetch-data="refetchData" />

        <concept-shortcut-team-edit :is-concept-shortcut-team-active.sync="isConceptShortcutTeamActive"
            :modalDate="modalDate" :conceptTeam="conceptTeam" :conceptTeamList="teamList" @refetch-data="refetchData" />
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useConceptApi from '@/composables/useConceptApi'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ConceptShortcutEdit from '@/components/ConceptShortcutEdit.vue'
import ConceptShortcutPmEdit from '@/components/ConceptShortcutPmEdit.vue'
import ConceptShortcutTeamEdit from '@/components/ConceptShortcutTeamEdit.vue'

import { ref } from '@vue/composition-api'
import { mapState } from 'vuex'
import { avatarText } from '@core/utils/filter'

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        ConceptShortcutEdit,
        ConceptShortcutPmEdit,
        ConceptShortcutTeamEdit,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalDate: null,
            conceptStatus: null,
            conceptPm: null,
            conceptTeam: null,
        }
    },
    setup() {
        const isConceptShortcutActive = ref(false)
        const isConceptShortcutPmActive = ref(false)
        const isConceptShortcutTeamActive = ref(false)
        const userData = JSON.parse(localStorage.getItem('userData'))
        const {
            fetchConcepts,
            downloadBuild,
            tableTeamColumns,
            tableColumns,
            perPage,
            currentPage,
            totalBuilds,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildListTable,
            refetchData,
            genreOptions,
            genreFilter,
            statusFilter,
            statusOptions,
            fetchGenre,
            genreFetchOptions,
            fetchConceptShortcutDetail,
            fetchPms,
            pmFilter,

            conceptPmList,
            teamFilter,
            teamList,
            fetchTeamList,
        } = useConceptApi()

        return {
            fetchConcepts,
            downloadBuild,
            tableTeamColumns,
            tableColumns,
            perPage,
            currentPage,
            totalBuilds,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildListTable,
            refetchData,
            genreOptions,
            statusOptions,
            genreFilter,
            statusFilter,
            fetchGenre,
            genreFetchOptions,
            userData,
            fetchConceptShortcutDetail,
            fetchPms,
            isConceptShortcutActive,
            isConceptShortcutPmActive,
            isConceptShortcutTeamActive,
            pmFilter,

            conceptPmList,
            teamFilter,
            teamList,
            fetchTeamList,
            avatarText
        }

    },
    mounted() {
        this.fetchGenre()
        this.fetchPms()
        if (this.$can('read', 'conceptTeamAccess')) {
            this.fetchTeamList()
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        confirmDelete(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the concept?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('conceptStore/deleteConcept', { id: id }).then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully deleted!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    }).then(() => {
                        this.refetchData()
                    })
                }
            })
        },
        shortcutMenu(concept, date, status) {
            this.conceptStatus = status
            this.modalDate = date
            this.fetchConceptShortcutDetail(concept, date)
            this.isConceptShortcutActive = true
        },

        shortcutPmMenu(concept, date, conceptPm, teamId) {
            this.conceptPm = conceptPm ?? ''
            this.modalDate = date
            this.fetchPms(teamId)
            this.fetchConceptShortcutDetail(concept, date)
            this.isConceptShortcutPmActive = true
        },

        shortcutTeamMenu(concept, date, teamId) {
            this.modalDate = date
            this.conceptTeam = teamId
            this.fetchConceptShortcutDetail(concept, date)
            this.isConceptShortcutTeamActive = true
        },
    },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
